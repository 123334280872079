import { t } from '@zupr/i18n'
import classnames from 'classnames'
import Link from 'next/link'

import { useDomain } from '../../../../context/domain'
import { useIsMobile } from '../../../../context/ux'
import { EagerImage } from '../../../../shared/components/figure'

import '../../../../../scss/react/pages/big-header.scss'

const DiscoverHeader = () => {
    const { entrepreneurStories, payoff, name, bigHeader, brand } = useDomain()
    const isMobile = useIsMobile()

    return (
        <div className="big-header">
            <div className="image-holder">
                <EagerImage
                    url={bigHeader}
                    cover
                    alt={`${brand} - ${payoff}`}
                />
            </div>

            <div className="big-header-content">
                <div className="titles">
                    <h1>{brand}</h1>
                    <p>{payoff}</p>
                </div>

                <div
                    className={classnames({
                        'desktop-links': !isMobile,
                        'mobile-links': isMobile,
                    })}
                >
                    <h4>
                        {t('Discover %{name}', {
                            name,
                        })}
                    </h4>
                    <ul>
                        <li>
                            <Link href="/producten">
                                <a>{t('Products')}</a>
                            </Link>
                        </li>
                        <li>
                            <Link href="/winkels">
                                <a>{t('Stores')}</a>
                            </Link>
                        </li>
                        {!!entrepreneurStories?.length && (
                            <li>
                                <Link href="/ondernemers">
                                    <a>{t('Entrepreneurs')}</a>
                                </Link>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default DiscoverHeader
